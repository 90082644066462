<template>
  <div
    :body-style="{ padding: '0px' }"
    class="ib-card rounded flex"
    :class="{ 'ib-card__active': isActiveNo === accNumber }"
    @click="chooseHandler"
  >
    <div class="ib-image-wrapper flex flex-center flex-middle rounded">
      <img src="@/assets/uploads/person.png" class="ib-image" alt="" />
    </div>
    <div class="content flex flex-column flex-center">
      <div class="ib-card-title">{{ name }}</div>

      <div class="ib-card-subtitle">{{ accNumber }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ibCard',
  props: {
    name: String,
    accNumber: Number,
    isActiveNo: Number
  },
  data() {
    return {};
  },
  methods: {
    chooseHandler() {
      this.$emit('choose');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/ibcard.scss';
</style>
