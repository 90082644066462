<template>
  <div class="level-card-wrapper">
    <div class="level-title" v-if="ibList.length !== 0">{{ $t('multiib.level', { level: level }) }}</div>
    <div class="content">
      <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="2" :lg="2">
          <div class="content-centered">
            <img
              src="@/assets/uploads/arrowLeft.png"
              class="content-image"
              :class="{ hidden: !hasPrevFlag }"
              @click="getPrevPageArr"
              data-testid="arrowLeft"
              alt="img"
            />
          </div>
        </el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="20">
          <el-row type="flex" justify="center" :gutter="10" style="flex-wrap: wrap">
            <el-col
              v-for="item in chunkedList[curPage]"
              :key="item.id"
              class="card-content-wrapper"
              :data-testid="item.id"
            >
              <ibCard
                :name="item.name"
                :accNumber="item.account"
                :isActiveNo="isActiveNo"
                @choose="chooseIB(item, level)"
              ></ibCard>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2">
          <div class="content-centered">
            <img
              src="@/assets/uploads/arrowRight.png"
              class="content-image"
              :class="{ hidden: !hasNextFlag }"
              @click="getNextPageArr"
              data-testid="arrowRight"
              alt="img"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ibCard from '@/components/ibLevel/ibCard';

export default {
  components: {
    ibCard: ibCard
  },
  name: 'levelList',
  props: {
    level: Number,
    ibList: Array
  },
  data() {
    return {
      hasNext: true,
      hasPrev: false,
      curPage: 0,
      chunkedList: [],
      size: 8,
      isActiveNo: null
    };
  },
  computed: {
    hasNextFlag() {
      return this.curPage < this.chunkedList.length - 1;
    },
    hasPrevFlag() {
      return this.curPage !== 0;
    },
    sizeFlag() {
      const matchesMobile = window.matchMedia('(max-width: 600px)').matches;
      const sizes = {
        matchesMobile: 12
      };
      //if mobile screen size, el-col => 4x3
      return matchesMobile ? sizes['matchesMobile'] : this.size;
    }
  },
  watch: {
    ibList: {
      immediate: true,
      handler(val) {
        this.curPage = 0;
        //if items more than 24, el-col has 2 lines (8x2). 8 per line.
        if (this.ibList.length >= 24) this.size = 16;
        this.chunkedList = this.chunk(val, this.sizeFlag);
        if (this.level === 1 && this.chunkedList[0] && this.chunkedList[0][0] && this.chunkedList[0][0].account) {
          this.isActiveNo = this.chunkedList[0][0].account;
          this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
        }
      }
    }
  },
  methods: {
    chunk(arr, size) {
      return arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
    },
    getNextPageArr() {
      this.curPage++;
    },
    getPrevPageArr() {
      this.curPage--;
    },
    chooseIB(item, level) {
      this.isActiveNo = item.account;
      this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/levelList.scss';
</style>
